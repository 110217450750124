img.carousel-img {
    position: relative;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 0;
    background-position: center center; /* Center the background image */
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0.6);
}

/* img.carousel-img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    filter: brightness(0.6);
} */

/* Media query for tablets */
/* @media (max-width: 768px) {
    img.carousel-img {
        object-fit: cover;
        height: 100vh;
        background-position: center center;
    }
} */

/* Media query for mobile devices */
/* @media (max-width: 576px) {
    img.carousel-img {
        object-fit: cover;
        height: 100vh;
        background-position: center center;
    }
} */