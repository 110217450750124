// Made by https://codepen.io/akshaycodes/

// Variables de colores
$base: #02182B;
// $primary: #D7263D;
$primary: #A32B35;
$accent: #ffffff;
$secondary: #a9a9a9;

// Mixins
@mixin fhover($color) {
  &:hover {
    transition: 350ms ease-in-out;
    color: $color;
    transform: scale(1.4);
  }
}

@mixin flex() {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

@mixin center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// Animación de "latido"
@keyframes beat {
  to {
    transform: scale(1.4);
  }
}

// Estilos de .overlay y .button
.overlay {
  width: 32.5px; // Aumentado de 25px
  height: 32.5px; // Aumentado de 25px
  transition: 0.4s linear;
  @include flex();
  outline: none; // Evitar el cursor titilante
  user-select: none; // Evitar la selección de texto

  &:hover {
    border-color: $primary;
    cursor: pointer;

    .button {

      &:before,
      &:after {
        width: 11.375px; // Aumentado de 8.75px
      }
    }
  }

  .button {
    width: 22.75px; // Aumentado de 17.5px
    height: 3.25px; // Aumentado de 2.5px
    background: $accent;
    transition: 900ms 175ms cubic-bezier(0.6, -0.46, 0.21, 1.3);
    outline: none; // Evitar el cursor titilante
    user-select: none; // Evitar la selección de texto
    border-radius: 20px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.9);

    &:before,
    &:after {
      content: '';
      transition: 400ms cubic-bezier(1, -0.81, 0.17, 1.2);
      width: 22.75px; // Aumentado de 17.5px
      height: 3.25px; // Aumentado de 2.5px
      display: flex;
      background: $accent;
      position: relative;
      border-radius: 20px;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.9);
    }

    &:before {
      transform-origin: right;
      top: -11.375px; // Aumentado de 8.75px
    }

    &:after {
      bottom: -7.8px; // Aumentado de 6px
      transform-origin: left;
      float: right;
    }
  }

  &.active {
    background: $primary;
    border-color: $primary;
    transform: rotate(270deg);

    .button {
      transform: rotate(-45deg);

      &:before,
      &:after {
        width: 11.375px; // Aumentado de 8.75px
        transform: rotate(-90deg);
      }
    }
  }
}